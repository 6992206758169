import { modalData } from './modalData';

// 共通項目
// モーダルを開きたい時に押す要素にjs-modalTriggerを設定
// data-modalTypeの内容によって、表示するmodalを選択する
const modalTrigger = document.querySelectorAll('.js-modalTrigger');
const modalTriggerNode = Array.prototype.slice.call(modalTrigger, 0);
const modalOverlay = document.querySelector('.js-modalOverlay');
const modalClose = document.querySelectorAll('.js-modalClose');
const modalCloseNode = Array.prototype.slice.call(modalClose, 0);
const modalBox = document.querySelector('.js-modalBox'); // 各モーダルに共通で操作を行いたい時に使用する

// 独自のモーダル
const modalShopping = document.querySelector('.js-modalShopping'); // headerのshoppingモーダル

// modal ui action
export function modalUi() {
  modalActionOpen();
  modalActionClose();
}

// modal open
function modalActionOpen() {
  modalTriggerNode.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      // modal create
      // js-modalTriggerのdata-shop
      const shop = targetBox.getAttribute('data-shop');

      // overlayを表示
      modalOverlay.classList.add('is-view');
      modalBox.classList.add('is-view');
      document.querySelector('body').classList.add('is-fixed');

      // data-shopによってモーダル内の情報を変更
      modalData(shop);
    });
  });
}

// modal close
function modalActionClose() {
  modalCloseNode.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      modalOverlay.classList.remove('is-view');
      document.querySelector('body').classList.remove('is-fixed');
      modalBox.classList.remove('is-view');
    });
  });

  modalOverlay.addEventListener('click', () => {
    modalOverlay.classList.remove('is-view');
    document.querySelector('body').classList.remove('is-fixed');
    modalBox.classList.remove('is-view');
  });
}
