import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

export function slider() {
  if (document.querySelector('.js-slider')) {
    const swiper = new Swiper('.js-slider', {
      loop: true,
      slidesPerView: 1, // PCでは3枚を中央に、.5で見切れ具合を調整
      spaceBetween: 40,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2.6, // PCでは3枚を中央に、.5で見切れ具合を調整
          spaceBetween: 40,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      },
    });
  }
}
