import simpleParallax from 'simple-parallax-js';

import { headerAction } from './modules/common/headerAction';
import { lazyLoad } from './modules/common/lazyLoad';
import { scrollmagic } from './modules/common/scrollmagic';
import { smoothScroll } from './modules/common/smoothScroll';
import { toggleClass } from './modules/common/toggleClass';
import { modal } from './modules/modal/modal';
import { movieConfig } from './modules/movieConfig/movieConfig';
import { slider } from './modules/slider/slider';
import { pageSmooth } from './modules/common/pageSmooth';
import { pageTop } from './modules/common/pageTop';
import { more } from './modules/common/more';

headerAction();
scrollmagic();
smoothScroll();
toggleClass();
modal();
slider();
pageTop();
more();

const thumb = document.getElementsByClassName('js-thumb');
new simpleParallax(thumb);

window.addEventListener('load', () => {
  lazyLoad();
  movieConfig();
  // pageSmooth();
  document.querySelector('body').classList.add('is-view');
});
