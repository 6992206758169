import { modalAdd } from './modalAdd';

export function modalData(shopData) {
  // 変数名は店舗情報

  /**** 国内 ****************************************/
  const kichisen = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kichisen.jpg',
    heading: '京懐石 吉泉',
    rank: 'ミシュランガイド★★★',
    text: '茶懐石の流れを汲んだ伝統的おもてなしを追求した日本料理の名店です。日本料理の正統を由縁としつつ、最上級な旬の素材を取り入れた伝統と進取の京料理を堪能頂けます。日常を忘れさせる優美な味と静謐な和の雰囲気を味わえる京都の名店であります。',
  };

  const kadowaki = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kadowaki.jpg',
    heading: '麻布 かどわき',
    rank: 'ミシュランガイド★★★',
    text: '各界の著名人が通う麻布十番を代表する和の名店。旬な食材を巧みに操る門脇俊哉氏にしか創れない、斬新かつ独創的な日本料理を堪能できます。中でも和食にトリュフを合わせた名物「トリュフご飯」は常識を覆す至福の味です。',
  };

  const losier = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_losier.jpg',
    heading: "ロオジエ（L'OSIER）",
    rank: 'ミシュランガイド★★★',
    text: '1973年東京銀座に誕生したフランス料理店銀座にゆかりの深い「柳」を意味するフランス語「osier」から命名された名店。伝統と革新が調和する、日本が世界に誇る最高峰のグランメゾンで至福のひと時を堪能できます。',
  };

  const kouan = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kouan.jpg',
    heading: '日本料理 幸庵',
    rank: 'ミシュランガイド★★★',
    text: '正統な日本料理に創意が織り込まれ、最後の甘未に至るまで、食感の違いや新しい味覚のを楽しめる料理、また器、設え、おもてなしも併せて全てが上質で神奈川県で唯一連続三ッ星を獲得している名店であります。',
  };

  const onodera = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_onodera.jpg',
    heading: '銀座 おのでら ロサンゼルス',
    rank: 'ミシュランガイド★★',
    text: '「銀座から世界へ」をコンセプトに世界各国6店舗で本物の江戸前寿司にこだわり、至高の食材を高いクオリティで「鮨」と日本の文化を発信しており、ハリウッドセレブの多くが訪れる全世界注目の名店です。',
  };

  const fukuju = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_fukuju.jpg',
    heading: '銀座 福樹',
    rank: 'ミシュランガイド★★',
    text: '主人と客人、食材と器。「客の心になりて亭主せよ、亭主の心なりて客いたせ」を玉条とする店主小野寺克弘氏。伝統とはただの模範だけではなく、意味を理解した上で形にし、本質を伝えていくべき‥そう語りかけられるような極上の日本料理を堪能頂けます。',
  };

  const fukudaya = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_fukudaya.jpg',
    heading: '紀尾井町 福田家',
    rank: 'ミシュランガイド★★',
    text: '北大路魯山人の美学を受け継ぎその伝統を守り続け、中庭や家具、調度品など日本文化に触れながら、四季折々の厳選素材を職人技で仕上げ、日本の伝統の醍醐味を堪能できる老舗料亭です。',
  };

  const ishibashi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_ishibashi.jpg',
    heading: '江戸川いしばし',
    rank: 'ミシュランガイド★',
    text: '明治43年に創業。「串打ち三年、裂き八年、焼き一生」を理念に注文を受けてから捌き、くし打ち、素焼きした後、蒸し器で蒸され、備長炭で秘伝のタレでじっくりと焼き上げます。白焼き、中落ちなどさまざまな鰻料理が楽しめる老舗の鰻料理の名店です。',
  };

  const amenimomakezu = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_amenimomakezu.jpg',
    heading: '和食 雨ニモマケズ',
    rank: '',
    text: '日本の食材が生み出す調和のすばらしさ、素材の新鮮さ、伝統的な手しごとへの敬意を大切に独自の調理法で「旬」を生かします。東京から発信する新しい和食。すべてのお客様に心あたたまるおもてなしをご堪能頂けます。',
  };

  const koguma = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_koguma.jpg',
    heading: '小熊',
    rank: '',
    text: '主人の小岩浩高氏は新橋の老舗京料理店を始め、複数の和食店で経験を重ねてきた料理人。「料理は素材がすべて」と、食材が持つ滋味を最大限活かすことに砕身しています。日本の四季の恵みである「旬」を活かした滋味溢れる実直なお料理が味わえます。',
  };

  const kasahara = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kasahara.jpg',
    heading: '焼き鳥　かさ原',
    rank: '',
    text: '世界で唯一の無菌鶏である「高坂鶏」を使用する焼き鳥店の名店。丁寧に串打ちした鶏肉を、赤く熱した炭火で丁寧かつ豪快に焼き上げていく過程では、炭職人佐野氏が火力を巧みに操り、部位によって火入れ方法を変えるなどまさに珠玉の一串。',
  };

  const byakko = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_byakko.jpg',
    heading: '白虹',
    rank: '',
    text: '食材のバランスや健康面においても高い評価を受け、良き日本の食文化を次世代に残し、伝統を継承し、日本料理の味や美しさを上質な時間と空間で味わって頂きたく、市ヶ谷の地で「白虹」は生まれました。情緒豊かな和空間で日本料理の神髄をご堪能ください。',
  };

  const nicho = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_nicho.jpg',
    heading: '料亭 二蝶',
    rank: '',
    text: '風光明媚な瀬戸内海に面した高松に昭和21年旅館として創業。瀬戸内海の新鮮な魚介類、この土地でしか手に入れることのできない野菜や果物など四季を通して楽しむことができます。料理人と生産者が二人三脚でここ香川県にしかない料理をご堪能出来ます。',
  };

  const gotoke = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_gotoke.jpg',
    heading: '和風料理 後藤家',
    rank: '',
    text: '伝統と現代が融合した日本料理がいただける創業60余年の老舗。「飛騨牛」や鮎をはじめ、旬の食材をふんだんに取り入れた季節感あふれる華やかな料理が揃い、和食器の中に洋食器を取り入れるなどの盛り付けもご堪能頂けます。',
  };

  const tokito = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_tokito.jpg',
    heading: 'うなぎ　時任',
    rank: '',
    text: '店主時任恵司氏は、名店で長年修行、フランスなど海外での経験を生かし、新ジャンル鰻フレンチ割烹を作り斬新で繊細な鰻料理を提供しています。鰻一筋18年、温故知新。伝統を守りつつ今までにない鰻料理の数々を味わって頂けます。',
  };

  const sushinobori = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_sushinobori.jpg',
    heading: 'すし拓幟',
    rank: '',
    text: '北海道余市に生まれ19歳で鮨職人の道を選び、約25年鮨と向き合い研鑽を続けてきた店主の伊藤氏。海のない岐阜にうまいといわれる鮨屋をつくりたい…豊洲、金沢、北海道、鹿児島から仕入れた海の幸と、岐阜の豊かな山の幸を組み合わせた逸品。',
  };

  const wabi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_wabi.jpg',
    heading: 'ＷＡＢＩやまどり',
    rank: '',
    text: 'その年、その季節で異なる食材の出来を見極め、最高品質の旬食材を、全国の産地から取り寄せています。一年十二カ月、毎月の献立はまたたく瞬間ほどの旬を表現しています。月ごとに、季節ごとに変わる器の中の彩りをお楽しみ頂けます。',
  };

  const chimoto = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_chimoto.jpg',
    heading: '京料理ちもと',
    rank: '',
    text: '1718年（享保3年）創業以来、数多くの文人墨客に愛された京都の老舗料理屋・京料理ちもと。 鴨川の流れを傍らに、代々伝わる伝統の味を守りつつ、常に新しい感覚を取り入れた京懐石をご賞味いただけます。京料理の良さが味わえる一皿をご堪能下さい。',
  };

  const kuroushi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kuroushi.jpg',
    heading: '焼肉 黒牛',
    rank: '',
    text: '焼肉一筋、焼肉道30年の料理長田原氏が牛肉の仕入れ、仕込み、提供まですべてにこだわり抜いた職人のおもてなしを心ゆくまで堪能していただけます。ご提供するお肉・素材は、全国各地から料理長自ら仕入れるA5ランクのブランド牛をご堪能頂けます。',
  };

  const maiogi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_maiogi.jpg',
    heading: '割烹　舞扇',
    rank: '',
    text: '京の祇園で「旬」の地のものを大切に、こだわり抜いた食材の持ち味を最大限に生かした調理、繊細な盛りつけ、器にもこだわった四季折々の伝統の味わいをご堪能頂けます。',
  };

  const uchitsu = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_uchitsu.jpg',
    heading: '天てんぷら うち津',
    rank: 'ミシュランガイド★★',
    text: '季節の一瞬を捉えその時期でしか味わえない、旬の味覚に出合えます。産地も時期もこだわり、全国の海の幸・山の幸をご堪能できるとともに熟成や昆布〆し“最高”の状態に引き上げた“唯一無二の天麩羅”をお召し上がり頂けます。',
  };

  const sadaya = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_sadaya.jpg',
    heading: '定谷',
    rank: '',
    text: '全国から旬の最高級食材を取り寄せて季節に合ったお料理へと昇華させ、そのときの素材の状態や当日の気候、さらにはお客様のお好みに合わせ創りあげた“コース料理”を“最高のタイミング”でご堪能ください。',
  };

  const tsukiya = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_tsukiya.jpg',
    heading: '焼鳥 月や',
    rank: '',
    text: '数々のミシュラン店で修業を重ねた日本料理の職人だからこそできる素材の味を最大限に引き出した「銘柄鶏 伊達鶏」を味わえます。日本文化継承の「焼鳥」と世界に誇る伝統「日本料理」の融合と生産者様の想いが込められた「最上の食材」はまさに逸品。',
  };

  const ishizuka = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_ishizuka.jpg',
    heading: '銀座 日本料理 いしづか',
    rank: '',
    text: '喜びも幸福も食に存在するという意の「食在喜福」を理念に、産地直送の食材にこだわり季節のにぎわいを感じさせる料理、そして純粋な『和』の心地よい空間の両方を堪能できる日本料理の名店です。石塚氏の日本料理への愛情が創り出す『生きた日本料理』は珠玉の逸品です。日本料理の真髄を堪能頂けます。',
  };

  const ichimonzi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_ichimonzi.jpg',
    heading: '神楽坂 一文字',
    rank: 'ミシュランガイド★',
    text: '神楽坂に店を構える正統派懐石料理の店。京懐石料理の重要人物である廣瀬和彦氏が表現する懐石の全てにこたわった渾身の一皿は、一口食べればこれが「本物」だとわかる一品でございます。',
  };

  const ifuki = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_ifuki.jpg',
    heading: '祇園 炭火割烹 いふき',
    rank: 'ミシュランガイド★★',
    text: '山本典央氏。野趣を残しながら風味を閉じ込め、素材の頂点の美味しさが引き出された逸品はまさに『味の芸術』です。美味に感激する時間をお過ごし頂けます。',
  };

  const tsuruya = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_tsuruya.jpg',
    heading: '京都　岡崎つる家',
    rank: 'ミシュランガイド★',
    text: '和の迎賓館ならではの【景観の美】・【空間の美】・【旬の美味】と、この上ない美しきおもてなしを受け継ぐ名店です。贅を尽くし、心を尽くす。京都ならでは「和のくつろぎ」の極みをご堪能頂けます。',
  };

  const ogawa = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_ogawa.jpg',
    heading: '祇園 おが和',
    rank: 'ミシュランガイド★',
    text: '小川洋輔氏によりさらなる進化を遂げた日本料理は、人の心をひきつけて離さない未知の美味。食べ慣れた地元の食通たちが何度も通う名店です。',
  };

  const hanayanagi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_hanayanagi.jpg',
    heading: '日本橋 おにく花柳',
    rank: 'ミシュランガイド★',
    text: '肉のエキスパート片柳シェフが織りなす和牛会席料理はまさに芸術品。窯元で直接選んだ美しい器に、美術品のように飾り付けられた上質で柔らかい和牛。肉の清澄で豊潤な旨味と奥深い風味を五感で堪能頂けます。',
  };

  const kien = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kien.jpg',
    heading: '赤坂　帰燕',
    rank: 'ミシュランガイド★',
    text: '燕が巣に帰るように羽を休めてほしい‥店名に想いを込めた寛ぎの場で正統派日本料理の神髄を味わえる名店です。「神は細部に宿る」という言葉を胸に、一品ごとに細部までこだわりを尽くしながら、お客様が帰ってきたくなる料理をご堪能できます。',
  };

  const kikuchi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kikuchi.jpg',
    heading: '西麻布　き久ち',
    rank: 'ミシュランガイド★',
    text: '四季折々の移ろいを『食』を通じて愉しむことができる日本料理の名店。自らの目利きで確信が持てた食材のみ振る舞う。素材を慈しみ、店主こだわりの土鍋で炊いたご飯は、最高の一品であります。',
  };

  const kissyo = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kissyo.jpg',
    heading: '吉祥　銀座本店',
    rank: '',
    text: '吉祥の精神「風雅を紡ぐ おもてなしの粋」。そこにはおもてなしの美学を追求した究極の美食と安らぎの空間がございます。美しい花々や書、優雅かつ格式ある空間が目と心をもてなし、村上料理長の【食の美学】が創り出す繊細な美味が舌をもてなしてくれます。至福のすべてが吉祥銀座本店にございます。',
  };

  const kurosu = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kurosu.jpg',
    heading: '神楽坂　くろす',
    rank: '',
    text: '黒須浩之氏の『美術館に並ぶ絵画のような彩りを皿の上に創りあげたい』との想いが隅々まで行き渡った正統派の日本料理店です。日本料理の伝統を踏襲しかつ、現代的で繊細なお料理の中に雪椿が使われております。',
  };

  const yonefuku = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_yonefuku.jpg',
    heading: '恵比寿　米福',
    rank: '',
    text: '店名どおりお米が主役の和食料理店です。全国各地から本当に美味しいお米だけを選りすぐって取り寄せ、注文を受けてから、萬古焼きの土鍋でじっくりと炊き上げます。数種類のご飯の食べ比べもできますので是非、「雪椿」を比較してみて下さい。',
  };

  const satoyama = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_satoyama.jpg',
    heading: '三田牛焼肉 里山 byウェスタ',
    rank: '',
    text: '厳格な品質基準を合格した、年間600頭しか流通しない幻の和牛“三田牛”。その上品な霜降りと柔らかく濃密な赤身を堪能できる銀座の名店です。“三田牛”の魅力を丁寧に表現した逸品は、お口の中で『極上のひと時』を感じさせてくれます。',
  };

  const shigematsu = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_shigematsu.jpg',
    heading: '銀座　茂松',
    rank: '',
    text: '素材と真摯に向きあう料理の想いが詰まった本格的な和食が魅力のお店。空間づくりにも強いこだわりがあり、もてなしの心が感じられ落ち着いた雰囲気で、松原氏の和食の世界を堪能できます。',
  };

  const suzuki = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_suzuki.jpg',
    heading: '割烹　すずき',
    rank: 'ミシュランガイド★',
    text: '独自に研究を重ね作り上げられる独創的な料理は、旬の食材を使ってその美味しさを引き出すことを大切にしている。『器は料理の服』という考えから、料理を盛る皿もご自身で特注した物を使うなど、美意識とこだわりが散りばめられた割烹料理店であります。',
  };

  const takiya = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_takiya.jpg',
    heading: '麻布十番　たきや',
    rank: '',
    text: '店主、笠本辰明氏が提供する料理は食材の持ち味を最大限に生かし、季節の最高の食材を天ぷらを味わうことができます。天ぷらの概念を覆す魅惑の口福体験が味わえる名店です。',
  };

  const tagetsu = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_tagetsu.jpg',
    heading: '青山　日本料理　太月',
    rank: 'ミシュランガイド★',
    text: '全国各地の厳選された食材を、茶懐石、料亭、割烹などで鍛えられた主人の巧みな技で味わい深い料理を堪能させてくれる名店です。主の精進性が色濃く反映された料理と共に雪椿をご堪能ください。',
  };

  const cheer_counter = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_cheer_counter.jpg',
    heading: '六本木　和食　チアカウンター',
    rank: '',
    text: '全国から選ばれたお米の食べ比べが出来る看板のない六本木の隠れ家。隠れ家的な雰囲気で本格和食を堪能でき、名物であるご飯はお米ソムリエにより厳選されたお米を土鍋で炊き上げるため、お米本来の旨味・香りをより一層堪能頂けます。',
  };

  const tsurutokame = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_tsurutokame.jpg',
    heading: '銀座　つるとかめ',
    rank: '',
    text: '和食の神髄を極めた7名の女性板前だけが板場に立ち、間近で手さばきを堪能頂けます。女性ならではのおもてなしでお客様を迎える日本初の新しいスタイルの日本料理店です。',
  };

  const haku = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_haku.jpg',
    heading: '池袋　正しい晩ごはん　白-はく-',
    rank: '',
    text: '四季折々の旬の素材をふんだんに使った創作本格和食の名店です。一番のこだわりである『米』は日本全国の厳選されたお米のみ取り扱い、また土釜で一合ずつ炊き上げられたご飯は絶品です。お米とよく合う逸品料理と共に雪椿をご堪能頂ければと思います。',
  };

  const hiiragiya = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_hiiragiya.jpg',
    heading: '銀座　柊家',
    rank: '',
    text: '美食家たちが集まる「銀座」に2018年春にオープンした懐石料理の店です。日本料理の原点である茶道の思想「守破離」を忠実に、味わって感じるだけでなく、日本文化ならではの心地よい時間を堪能できる名店です。',
  };

  /**** 国外 ****************************************/
  const chef = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_chef.jpg',
    heading: 'Chef’s Table <br>at Brooklyn Farea',
    rank: 'ミシュランガイド★★★',
    text: 'ニューヨークの中で最も予約が難しいミシュラン3つ星の名店。ニューヨーク№1シェフのシーザーラミレス氏が創るフレンチと日本食を融合した料理は、まさにミシュラン3つ星の定義である「ここに行くためにわざわざ旅行する価値のある」美味しさです。',
  };

  const naka = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_naka.jpg',
    heading: 'N/NAKA',
    rank: 'ミシュランガイド★★★',
    text: 'ロサンゼルスでミシュラン三ツ星を獲得する最も有名なレストランです。シェフのニキ・ナカヤマ氏は有名なタカオレストランで日本料理の真髄を学び、懐石の芸術的および技術的概念を適用し、食事ごとに進化し続ける季節の物語を堪能できます。',
  };

  const hayato = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_hayato.jpg',
    heading: 'HAYATO',
    rank: 'ミシュランガイド★',
    text: '名店、神楽坂石かわで修業した隼人氏はロサンゼルスで多くのメディアから賞賛を受けている話題のレストランです。食材の品質からこだわり抜き、本格的な和食懐石が堪能できる隠れ家的名店です。',
  };

  const matsumoto = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_matsumoto.jpg',
    heading: 'MATSUMOTO RESTAURANT',
    rank: 'ミシュランガイド★',
    text: 'ビバリーヒルズで江戸前鮨をアラカルトで頼める名店です。日本から旬な魚にこだわり抜き洗練された握りは本物です。',
  };

  const tsukimi = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_tsukimi.jpg',
    heading: 'TSUKIMI',
    rank: 'ミシュランガイド★',
    text: '最高品質の日本の旬の食材を強調した印象的な伝統的な懐石料理。現代風にアレンジし、特定の料理だけでなく主成分がそれ自体を語らせる料理には、いくつかの興味深い食感と風味の組み合わせがありました。',
  };

  const kissaki = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kissaki.jpg',
    heading: 'KISSAKI',
    rank: 'ミシュランガイド★',
    text: '高品質の寿司グレードの魚、キサキと炉端グリルの品揃えを融合させています。日本から直接魚を輸入する。おまかせ寿司と懐石の伝統に根ざしたレストランで、調達、季節、品質に配慮し、五感でお料理を楽しめます。',
  };

  const kosaka = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_kosaka.jpg',
    heading: 'kosaka',
    rank: 'ミシュランガイド★',
    text: '芸術的で卓越した、シンプルだがエレガント、それでありながら日本の旬を活かした厳選された食材で独創的な一皿を創り上げるシェフ高坂氏。地元の季節の食事にこだわり、過去10年連続でミシュランの星を獲得したシェフとしてその名を轟かせる。',
  };

  const momofuku = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_momofuku.jpg',
    heading: 'MOMOFUKU KO',
    rank: 'ミシュランガイド★',
    text: '2008年に創業、2009年にミシュラン星獲得以来、星を維持している。日本の懐石の伝統、季節性、メンターや仲間の革新が様々な料理に導かれ、様々な評価を受けている人気店。',
  };

  const sakagura = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_sakagura.jpg',
    heading: 'SAKAGURA',
    rank: '',
    text: '知る人ぞ知る隠れ家的お店。日本ならではの最高の“おもてなし”を叶えてくれる老舗レストラン。本格的な日本料理と共に原酒や雫酒など貴重なお酒も堪能できます。',
  };

  const yopparai = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_yopparai.jpg',
    heading: 'YOPPPARAI',
    rank: '',
    text: 'ニューヨーカーに日本酒で酔っぱらってもらいたいと思いからニューヨークで本格居酒屋を展開。伝統的な料理や冒険的な料理と組み合わせた多種多様なおもてなしと豊富な品揃えの日本酒を揃えミシュランガイドにも掲載の人気店です。',
  };

  const okuda = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_okuda.jpg',
    heading: 'OKUDA',
    rank: 'ミシュランガイド★',
    text: 'シェフの奥田氏は静岡で生まれ、様々な地で修業した後、2017年にニューヨーク店をオープンしミシュランガイド二つ星を獲得。本格的な旬の日本料理と心温まるおもてなしで日本料理だけでなく、日本の素晴らしい伝統、文化を感じながらお料理を満喫していただけます。',
  };

  const hanare = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_hanare.jpg',
    heading: 'Hanare',
    rank: 'ミシュランガイド★',
    text: 'アートでカラフルな建物内にあるＨana reは、日本だけでなく世界中から厳選された食材を使用し、アートな料理を演出。シェフ横山敦氏はシェフオブザイヤーにも選ばれ影響力のあるレストランです。',
  };

  const jonathan = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_jonathan.jpg',
    heading: 'JONATHAN　CLUB',
    rank: '',
    text: 'ジョナサンクラブは、100年以上の歴史を持つプライベートソーシャルクラブです。西海岸の旗艦と評され、カリフォルニアで最も特別な社交場であり、和食の全てに雪椿を使用しております。',
  };

  const masaki = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_masaki.jpg',
    heading: 'Sushi Masaki Saito',
    rank: '',
    text: 'カナダで最もハイエンドなSushi Masaki Saitoは、ニューヨークでミシュラン２ツ星を獲得したスターシェフがオープン。こだわり抜いた本物の江戸前寿司を堪能できる至高の名店です。',
  };

  const cagen = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_cagen.jpg',
    heading: 'Cagen',
    rank: 'ミシュランガイド★',
    text: 'イーストビレッジでレストランを構える欧米人オーナーや、シェフの間で噂になっているミシュラン1つ星の日本料理店です。伝統的な割烹料理の洗練された芸術に革新的な技が組み合わされた本格和食が堪能頂けます。',
  };

  const onodera_ros = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_onodera_ros.jpg',
    heading: '銀座おのでら ロサンゼルス',
    rank: 'ミシュランガイド★★',
    text: '2016年1月スイスで開催された「第23回・サンモリッツグルメフェスティバル」に鮨代表として選出されるほどの珠玉の鮨店で、「銀座から世界へ」をコンセプトに本物の江戸前寿司にこだわり、ニューヨーク店ではミシュラン二つ星を獲得した全世界注目の名店です。',
  };

  const uchu = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_uchu.jpg',
    heading: 'Uchu',
    rank: 'ミシュランガイド★',
    text: '伝説のシェフ市村英治氏が手掛けるUchuは懐石料理と寿司が融合し、素材に徹底的にこだわり季節ごとに毎週変わるお任せメニューを中心に、伝統的な日本食を堪能することができる名店です。',
  };

  const boulud = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_boulud.jpg',
    heading: 'Café Boulud',
    rank: 'ミシュランガイド★',
    text: 'ミシュラン三つ星を獲得している「ダニエル」の姉妹店。2010年に一つ星を獲得。クラッシックで落ち着いた大人の雰囲気が漂い、和を取り入れたフレンチを上品で繊細な味を楽しめます。',
  };

  const satsukinyc = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_satsukinyc.jpg',
    heading: 'SatsukiNYC',
    rank: 'ミシュランガイド★',
    text: 'ニューヨークではレジェンドと称される鈴木俊雄氏。鮨バーで、そのレジェンドがこだわりぬいた食材を本場の江戸前寿司が堪能できます。',
  };

  const sushizo = {
    img: '/wp-content/themes/yukitsubaki/assets/img/enjoy/enjoy_modal_shop_pic_sushizo.jpg',
    heading: 'Sushi Zo NewYork',
    rank: 'ミシュランガイド★',
    text: '世界各国6店舗でSushi Zo Styleを確立する名店。シェフが江戸前寿司の伝統を活かし、ソースや調味料を工夫するだけでなく徹底して素材にこだわり、ミシュラン一つ星を獲得した質の高い寿司店です。',
  };

  // 反映
  switch (shopData) {
    /**** 国内 ****************************************/
    case 'kichisen': {
      modalAdd(kichisen);
      break;
    }
    case 'kadowaki': {
      modalAdd(kadowaki);
      break;
    }
    case 'losier': {
      modalAdd(losier);
      break;
    }
    case 'kouan': {
      modalAdd(kouan);
      break;
    }
    case 'onodera': {
      modalAdd(onodera);
      break;
    }
    case 'fukuju': {
      modalAdd(fukuju);
      break;
    }
    case 'fukudaya': {
      modalAdd(fukudaya);
      break;
    }

    case 'ishibashi': {
      modalAdd(ishibashi);
      break;
    }
    case 'amenimomakezu': {
      modalAdd(amenimomakezu);
      break;
    }
    case 'koguma': {
      modalAdd(koguma);
      break;
    }
    case 'kasahara': {
      modalAdd(kasahara);
      break;
    }
    case 'hakuzan': {
      modalAdd(hakuzan);
      break;
    }
    case 'byakko': {
      modalAdd(byakko);
      break;
    }
    case 'nicho': {
      modalAdd(nicho);
      break;
    }
    case 'gotoke': {
      modalAdd(gotoke);
      break;
    }
    case 'tokito': {
      modalAdd(tokito);
      break;
    }
    case 'sushinobori': {
      modalAdd(sushinobori);
      break;
    }
    case 'wabi': {
      modalAdd(wabi);
      break;
    }
    case 'chimoto': {
      modalAdd(chimoto);
      break;
    }
    case 'kuroushi': {
      modalAdd(kuroushi);
      break;
    }
    case 'maiogi': {
      modalAdd(maiogi);
      break;
    }
    case 'uchitsu': {
      modalAdd(uchitsu);
      break;
    }
    case 'sadaya': {
      modalAdd(sadaya);
      break;
    }
    case 'kasumi': {
      modalAdd(kasumi);
      break;
    }
    case 'tsukiya': {
      modalAdd(tsukiya);
      break;
    }
    case 'ishizuka': {
      modalAdd(ishizuka);
      break;
    }
    case 'ichimonzi': {
      modalAdd(ichimonzi);
      break;
    }
    case 'ifuki': {
      modalAdd(ifuki);
      break;
    }
    case 'tsuruya': {
      modalAdd(tsuruya);
      break;
    }
    case 'ogawa': {
      modalAdd(ogawa);
      break;
    }
    case 'hanayanagi': {
      modalAdd(hanayanagi);
      break;
    }
    case 'kien': {
      modalAdd(kien);
      break;
    }
    case 'kikuchi': {
      modalAdd(kikuchi);
      break;
    }
    case 'kissyo': {
      modalAdd(kissyo);
      break;
    }
    case 'kurosu': {
      modalAdd(kurosu);
      break;
    }
    case 'yonefuku': {
      modalAdd(yonefuku);
      break;
    }
    case 'satoyama': {
      modalAdd(satoyama);
      break;
    }
    case 'shigematsu': {
      modalAdd(shigematsu);
      break;
    }
    case 'suzuki': {
      modalAdd(suzuki);
      break;
    }
    case 'takiya': {
      modalAdd(takiya);
      break;
    }
    case 'tagetsu': {
      modalAdd(tagetsu);
      break;
    }
    case 'cheer_counter': {
      modalAdd(cheer_counter);
      break;
    }
    case 'tsurutokame': {
      modalAdd(tsurutokame);
      break;
    }
    case 'haku': {
      modalAdd(haku);
      break;
    }
    case 'hiiragiya': {
      modalAdd(hiiragiya);
      break;
    }

    /**** 国外 ****************************************/
    case 'chef': {
      modalAdd(chef);
      break;
    }
    case 'naka': {
      modalAdd(naka);
      break;
    }

    case 'hayato': {
      modalAdd(hayato);
      break;
    }
    case 'matsumoto': {
      modalAdd(matsumoto);
      break;
    }
    case 'tsukimi': {
      modalAdd(tsukimi);
      break;
    }
    case 'kissaki': {
      modalAdd(kissaki);
      break;
    }
    case 'kosaka': {
      modalAdd(kosaka);
      break;
    }
    case 'momofuku': {
      modalAdd(momofuku);
      break;
    }
    case 'sakagura': {
      modalAdd(sakagura);
      break;
    }
    case 'yopparai': {
      modalAdd(yopparai);
      break;
    }
    case 'okuda': {
      modalAdd(okuda);
      break;
    }
    case 'hanare': {
      modalAdd(hanare);
      break;
    }
    case 'urasawa': {
      modalAdd(urasawa);
      break;
    }
    case 'jonathan': {
      modalAdd(jonathan);
      break;
    }
    case 'masaki': {
      modalAdd(masaki);
      break;
    }
    case 'cagen': {
      modalAdd(cagen);
      break;
    }
    case 'onodera_ros': {
      modalAdd(onodera_ros);
      break;
    }
    case 'uchu': {
      modalAdd(uchu);
      break;
    }
    case 'boulud': {
      modalAdd(boulud);
      break;
    }
    case 'satsukinyc': {
      modalAdd(satsukinyc);
      break;
    }
    case 'sushizo': {
      modalAdd(sushizo);
      break;
    }
  }
}
