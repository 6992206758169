import { pageSmooth } from './pageSmooth';

export function more() {
  const moreBtn = document.querySelectorAll('.js-moreBtn');
  const moreBtnTriggerList = Array.prototype.slice.call(moreBtn, 0);

  moreBtnTriggerList.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      if (targetBox.classList.contains('is-active')) {
        targetBox.classList.remove('is-active');

        if (targetBox.classList.contains('is-domestic')) {
          const element = document.getElementById('domesticTop');
          const rect = element.getBoundingClientRect();
          const header = document.getElementById('header');
          const elemtop = rect.top + window.pageYOffset - header.clientHeight;

          setTimeout(() => {
            document.documentElement.scrollTop = elemtop;
          }, 200);
        }

        if (targetBox.classList.contains('is-overseas')) {
          const element = document.getElementById('overseasTop');
          const rect = element.getBoundingClientRect();
          const header = document.getElementById('header');
          const elemtop = rect.top + window.pageYOffset - header.clientHeight;

          setTimeout(() => {
            document.documentElement.scrollTop = elemtop;
          }, 200);
        }
      } else {
        targetBox.classList.add('is-active');
      }

      // const prevEle = targetBox.previousElementSibling.childNodes;
      // const windowSize = window.innerWidth;
      // let shopListArray = [];
      // let shopListCount = 0;
      // let shopListArrayNext;
      // for (let i = 0; i < prevEle.length; i++) {
      //   let thisEle = prevEle[i];
      //   if (thisEle.nodeName == 'LI') {
      //     if (windowSize > 767) {
      //       if (!thisEle.classList.contains('is-view')) {
      //         if (shopListCount < 3) {
      //           shopListArray[shopListCount] = thisEle;
      //           shopListCount++;
      //         }
      //       }
      //     } else {
      //       if (!thisEle.classList.contains('is-view')) {
      //         if (shopListCount < 2) {
      //           shopListArray[shopListCount] = thisEle;
      //           shopListCount++;
      //         }
      //       }
      //     }
      //   }
      //   if (shopListArray[shopListArray.length - 1] == prevEle[prevEle.length - 2]) {
      //     shopListArrayNext = true;
      //   } else {
      //     shopListArrayNext = false;
      //   }
      // }
      // if (windowSize > 767) {
      //   if (shopListArray.length == 3 && shopListArrayNext == false) {
      //     for (let i = 0; i < shopListArray.length; i++) {
      //       shopListArray[i].classList.add('is-view');
      //     }
      //     setTimeout(() => {
      //       // pageSmooth();
      //     }, 100);
      //   } else {
      //     for (let i = 0; i < shopListArray.length; i++) {
      //       shopListArray[i].classList.add('is-view');
      //     }
      //     targetBox.remove();
      //     setTimeout(() => {
      //       // pageSmooth();
      //     }, 100);
      //   }
      // } else {
      //   if (shopListArray.length == 2 && shopListArrayNext == false) {
      //     for (let i = 0; i < shopListArray.length; i++) {
      //       shopListArray[i].classList.add('is-view');
      //     }
      //     setTimeout(() => {
      //       // pageSmooth();
      //     }, 100);
      //   } else {
      //     for (let i = 0; i < shopListArray.length; i++) {
      //       shopListArray[i].classList.add('is-view');
      //     }
      //     targetBox.remove();
      //     setTimeout(() => {
      //       // pageSmooth();
      //     }, 100);
      //   }
      // }
      // shopListArray.length = 0;
    });
  });
}
