export function toggleClass() {
  const toggle = document.querySelectorAll('.js-toggle');
  const toggleTriggerList = Array.prototype.slice.call(toggle, 0);

  toggleTriggerList.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      if (targetBox.classList.contains('is-active')) {
        targetBox.classList.remove('is-active');
      } else {
        targetBox.classList.add('is-active');
      }
    });
  });
}
