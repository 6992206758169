import { pageSmooth } from './pageSmooth';

export function lazyLoad() {
  const lazy = document.querySelectorAll('.js-lazyload');
  const io = new IntersectionObserver(inViewport, {
    threshold: [0],
  });

  Array.from(lazy).forEach(element => {
    io.observe(element);
  });

  function inViewport(entries, observer) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        const imgEl = entry.target;
        imgEl.src = imgEl.dataset.src;

        imgEl.addEventListener('load', () => {
          imgEl.classList.add('is-lazyloaded');
        });

        io.unobserve(entry.target);

        setTimeout(() => {
          // pageSmooth();
        }, 200);
      }
    });
  }
}
