export function headerAction() {
  const body = document.querySelector('body');
  const main = document.querySelector('main');
  const headerEle = document.querySelector('.js-header');
  const hamburgerTrigger = document.querySelector('.js-hamburger');
  const gnavTrigger = document.querySelector('.js-gnav');

  hamburgerTrigger.addEventListener('click', () => {
    if (hamburgerTrigger.classList.contains('is-active')) {
      body.classList.remove('is-fixed');
      headerEle.classList.remove('is-active');
      hamburgerTrigger.classList.remove('is-active');
      gnavTrigger.classList.remove('is-view');
    } else {
      body.classList.add('is-fixed');
      headerEle.classList.add('is-active');
      hamburgerTrigger.classList.add('is-active');
      gnavTrigger.classList.add('is-view');
    }
  });

  if (main.classList.contains('js-white')) {
    headerEle.classList.add('is-white');
  }

  if (main.classList.contains('js-black')) {
    headerEle.classList.add('is-black');
  }
}
