export function modalAdd(modalData) {
  const modalCont = document.querySelector('.js-modalCont');
  const modalChild = document.getElementById('modalCont');

  if (modalChild) {
    modalChild.parentNode.removeChild(modalChild);
  }

  modalCont.insertAdjacentHTML('beforeend', '<div id="modalCont" class="c-modal_inner"><div class="c-modal_pic"><img src="' + modalData.img + '" alt=""></div><div class="c-modal_box"><p class="c-modal_heading c-text--xl--semiBold c-color_text--gold">' + modalData.heading + '</p><p class="c-modal_lead c-text--s--semiBold c-color_text--gold">' + modalData.rank + '</p><div class="c-modal_scroll"><p class="c-modal_text c-text">' + modalData.text + '</p></div></div></div>');
}
